/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';
import React, { Component } from 'react';
import { getTranslate } from 'react-localize-redux';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import moment from 'moment';
import { useSeriesShipment } from '../state/parcelFlow';
import { STATUS, fetchSeriesInfo } from '../state/series';
import Button from '../components/Button';
import ShipmentInfo from '../components/ShipmentInfo';
import { locNavigate } from '../state/session';
import Accordion from '../components/Accordion';

const RoundedContainer = ({ children, onClick, ...props }) => (
  <div
    sx={{
      my: 2,
      py: 2,
      px: 3,
      borderWidth: 2,
      borderStyle: 'solid',
      borderColor: 'border',
      borderRadius: 1,
      ...props,
    }}
    onClick={onClick}
  >
    {children}
  </div>
);

class SeriesInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isFilledExpanded: !!this.props.expandedShipment,
      isSentExpanded: false,
    };
    this.firstRender = true;
  }

  componentDidMount() {
    this.firstRender = false;
    this.refreshInfoIfNeeded();
    this.timer = setInterval(() => this.refreshInfoIfNeeded(), 10000);
  }

  componentWillUnmount() {
    clearInterval(this.timer);
    this.timer = null;
  }

  refreshInfoIfNeeded() {
    const now = +new Date();
    const { updateTimestamp, email, productId, firstFetched } = this.props.series;

    const timeSinceFirstFetch = (now - firstFetched) / 1000;
    const forcedLogoutTime = 10 * 60 * 60; // 10 hours
    if (timeSinceFirstFetch > forcedLogoutTime) {
      this.props.locNavigate('/sarjapaketti'); // not actual logout, just hide multi-parcel details
      return;
    }

    const timeElapsed = (now - updateTimestamp) / 1000;
    const refreshInterval = 10 * 60; // 10 minutes
    if (timeElapsed > refreshInterval) {
      this.props.fetchSeriesInfo(email, productId, true, () => {});
    }
  }

  toggleFilledExpanded = () => {
    this.setState({ isFilledExpanded: !this.state.isFilledExpanded });
  };

  toggleSentExpanded = () => {
    this.setState({ isSentExpanded: !this.state.isSentExpanded });
  };

  onBackClick = () => {
    window.history.back();
    // this.props.locNavigate('/sarjapaketti');
  };

  useShipment = async (shipment, isUnused) => {
    await this.props.useSeriesShipment(this.props.series, shipment, isUnused);
  };

  render() {
    const isFilledExpanded = !!this.props.expandedShipment;
    const { isSentExpanded } = this.state;
    const { series, translate } = this.props;
    const { sender } = series;

    const sentShipments = [];
    const unusedShipments = [];
    const filledShipments = [];
    const allShipments = {
      [STATUS.SENT]: sentShipments,
      [STATUS.FILLED]: filledShipments,
      [STATUS.UNUSED]: unusedShipments,
    };
    (series.shipments || []).forEach(s => allShipments[s.status].push(s));

    return (
      <>
        {this.renderBackButton()}
        {this.renderProductInfo(series)}

        <Styled.h3 sx={{ color: 'secondary' }}>{translate('series.info.title')}</Styled.h3>
        {this.renderUnusedShipments(unusedShipments)}
        {this.renderFilledShipments(filledShipments, isFilledExpanded)}
        {this.renderUsedShipments(sentShipments, isSentExpanded)}
        {this.renderSender(sender)}
      </>
    );
  }

  renderBackButton() {
    const { translate } = this.props;
    return (
      <Box>
        <Button onClick={this.onBackClick} variant="plain">
          {translate('backButton')}
        </Button>
      </Box>
    );
  }

  renderProductInfo(series) {
    const { translate } = this.props;
    return (
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr 1fr',
          gridColumnGap: 3,
          maxWidth: 500,
          mb: 4,
          mt: 3,
        }}
      >
        <Box>{translate('series.info.product')}</Box>
        <Box sx={{ fontWeight: 'medium' }}>{series.product.description}</Box>
        {series.services && series.services.length > 0 && (
          <>
            <Box>{translate('series.info.service')}</Box>
            <Box sx={{ fontWeight: 'medium' }}>
              {series.services.map((s, i) => (
                <span key={i}>{s.description}</span>
              ))}
            </Box>
          </>
        )}
        <Box>{translate('series.info.number')}</Box>
        <Box sx={{ fontWeight: 'medium' }}>{series.productId}</Box>
      </Box>
    );
  }

  renderUnusedShipments(unusedShipments) {
    if (unusedShipments.length === 0) return null;
    const { translate } = this.props;
    const firstUnused = unusedShipments[0];
    return (
      <RoundedContainer color="primary">
        <Flex
          sx={{
            alignItems: 'baseline',
          }}
        >
          <Box sx={{ flexGrow: 1, fontWeight: 'medium', fontSize: 3 }}>
            {translate('series.info.unused') + ` (${unusedShipments.length})`}
          </Box>
          <Box sx={{ mx: 2, flexShrink: 1 }}>
            {translate('series.info.validUntil', {
              date: moment(firstUnused.validUntil).format('D.M.YYYY'),
            })}
          </Box>
          <Button sx={{ ml: [2] }} onClick={() => this.useShipment(firstUnused, true)}>
            {translate('series.info.useButton')}
          </Button>
        </Flex>
      </RoundedContainer>
    );
  }

  renderFilledShipments(filledShipments, isFilledExpanded) {
    if (filledShipments.length === 0) return null;
    const { translate } = this.props;
    return (
      <Accordion
        title={translate('series.info.filled') + ` (${filledShipments.length})`}
        sx={{ bg: 'white', border: '2px solid', borderColor: 'border', borderRadius: '8px', px: 3, py: 2, my: 2 }}
        isExpanded={isFilledExpanded}
      >
        {filledShipments.map(s => (
          <ShipmentInfo
            key={s.shipmentNumber}
            scrollVisible={s.shipmentNumber === this.props.expandedShipment && this.firstRender}
            shipment={s}
            onUpdate={() => this.useShipment(s, false)}
          />
        ))}
      </Accordion>
    );
  }

  renderUsedShipments(sentShipments, isSentExpanded) {
    if (sentShipments.length === 0) return null;
    const { translate } = this.props;
    return (
      <Accordion
        title={translate('series.info.sent') + ` (${sentShipments.length})`}
        sx={{ bg: 'white', border: '2px solid', borderColor: 'border', borderRadius: '8px', px: 3, py: 2 }}
        isExpanded={isSentExpanded}
      >
        {sentShipments.map(s => (
          <ShipmentInfo key={s.shipmentNumber} shipment={s} />
        ))}
      </Accordion>
    );
  }

  renderSender(sender) {
    if (!sender.name) return null;
    const { translate } = this.props;
    return (
      <Box sx={{ mt: 4 }}>
        <Styled.h4 sx={{ mb: 2, color: 'secondary' }}>{translate('series.info.sender')}</Styled.h4>
        <div>{sender.name}</div>
        <div>{sender.phone}</div>
        <div>{sender.email}</div>
      </Box>
    );
  }
}

const mapStateToProps = state => ({
  translate: getTranslate(state.localize),
  series: state.series,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators({ locNavigate, fetchSeriesInfo, useSeriesShipment }, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(SeriesInfo);
