/** @jsx jsx */
import { jsx, Box } from 'theme-ui';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { ErrorMessage, Form, withFormik } from 'formik';
import * as yup from 'yup';
import Button from '../components/Button';
import FormField from './FormField';

const SSForm = props => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      style={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
      }}
    >
      <FormField name="email" label={translate('series.form.email')} />
      <FormField name="productId" label={translate('series.form.number')} />
      <Box sx={{ mt: 3 }}>
        <Button
          type="submit"
          onClick={props.handleSubmit}
          disabled={props.isSubmitting}
        >
          {translate('series.form.button')}
        </Button>
      </Box>
      <ErrorMessage name="submit">
        {msg => <div style={{ color: 'red' }}>{msg}</div>}
      </ErrorMessage>
    </Form>
  );
};

const seriesSchema = translate =>
  yup.object().shape({
    email: yup
      .string()
      .required(translate('form.requiredField'))
      .email(translate('form.invalidEmail')),
    productId: yup.string().required(translate('form.requiredField')),
  });

export default withFormik({
  mapPropsToValues: ({ email, productId }) => ({
    email: email || '',
    productId: productId || '',
    submit: '', // for error message
  }),

  validationSchema: ({ translate }) => seriesSchema(translate),

  handleSubmit: (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },

  displayName: 'SeriesShipmentForm',
})(SSForm);
