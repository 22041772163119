/** @jsx jsx */
import { useCallback, useEffect } from 'react';
import { jsx, Flex, Box, Styled, Grid } from 'theme-ui';
import { getTranslate } from 'react-localize-redux';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash/get';
import Layout from '../components/layout';
import Container from '../components/Container';
import SeriesInfo from '../components/SeriesInfo';
import SeriesShipmentForm from '../components/SeriesShipmentForm';
import { MultiparcelWidget } from '../components/widgets';
import { PAGE } from '../state/parcelFlow';
import { fetchSeriesInfo } from '../state/series';
import ForgotSeriesNumber from '../components/ForgotSeriesNumber';
import { locNavigate } from '../state/session';
import { isBrowser } from '../utils';
import Spinner from '../components/Spinner';
import DeliveryOptions from '../parcelFlow/DeliveryOptions';
import Recipient from '../parcelFlow/Recipient';
import PickupPoint from '../parcelFlow/PickupPoint';
import 'url-search-params-polyfill'; // for IE
import * as analytics from '../utils/analytics';

const AskSeriesId = ({ pageContext }) => {
  const localize = useSelector(state => state.localize);
  const translate = getTranslate(localize);
  const formEmail = useSelector(state => get(state, 'series.email') || get(state, 'session.user.email')) || '';
  const dispatch = useDispatch();
  const paths = (pageContext && pageContext.paths) || {};
  const locale = (pageContext && pageContext.locale) || 'en';
  const onSubmit = useCallback(
    ({ email, productId }, { setSubmitting, setFieldError }) => {
      dispatch(
        fetchSeriesInfo(email, productId, false, found => {
          setSubmitting(false);
          if (!found) {
            const translate = getTranslate(localize);
            setFieldError('submit', translate('series.form.notFound'));
          }
          if (productId === found) {
            dispatch(locNavigate('/sarjapaketti', `?productId=${productId}`));
          }
        })
      );
    },
    [dispatch, localize]
  );

  const email = formEmail;
  const productId = '';
  const formProps = { translate, email, productId, onSubmit };
  return (
    <Layout title={translate('series.form.title')} paths={paths} locale={locale}>
      <Container sx={{ py: [3, null, 4, 5] }}>
        <Flex
          sx={{
            justifyContent: 'center',
            flexWrap: ['wrap', null, null, 'nowrap'],
          }}
        >
          <Box sx={{ maxWidth: 640, mb: 4 }}>
            <Styled.h1 sx={{ color: 'secondary' }}>{translate('series.form.title')}</Styled.h1>
            <Styled.p>{translate('series.form.description')}</Styled.p>
            <SeriesShipmentForm {...formProps} />
            <ForgotSeriesNumber />
          </Box>

          <Grid
            sx={{
              maxWidth: 640,
              alignSelf: 'flex-start',
              width: ['100vw', null, null, 400],
              ml: [-3, 0, 0, 5],
              mr: [-3, 0],
            }}
            gap={[2, 3]}
          >
            <MultiparcelWidget data={{ visualStyle: 'Pink' }} sidebar noSendButton={true} />
          </Grid>
        </Flex>
      </Container>
    </Layout>
  );
};

const SeriesInfoWrapper = ({ location: { state: pageState }, productId, pageContext }) => {
  const expandedShipment = pageState && pageState.expandedShipment;
  const translate = getTranslate(useSelector(state => state.localize));
  const series = useSelector(state => state.series) || {};
  const hasProductInfo = series.productId === productId;
  const paths = (pageContext && pageContext.paths) || {};
  const useParcel = useSelector(state => state.parcelFlow) || {};

  if (!hasProductInfo) {
    return (
      <Layout paths={paths}>
        <Flex
          sx={{
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 360,
          }}
        >
          <Box sx={{ position: 'relative', height: 48 }}>
            <Spinner size="medium" color="primary" />
          </Box>
          <Box>{translate('series.loading')}</Box>
        </Flex>
      </Layout>
    );
  }

  return (
    <Layout paths={paths}>
      <Container sx={{ maxWidth: 1024, py: [2, 3, null, 4] }}>
        {useParcel.seriesId ? (
          (() => {
            switch (useParcel.page) {
              case PAGE.SELECT_DELIVERY:
                return <DeliveryOptions />;
              case PAGE.RECIPIENT:
                return <Recipient />;
              case PAGE.PICKUP_POINT:
                return <PickupPoint />;
              default:
                return <h1>Virhetilanne</h1>;
            }
          })()
        ) : (
          <SeriesInfo expandedShipment={expandedShipment} />
        )}
      </Container>
    </Layout>
  );
};

// eslint-disable-next-line react/display-name
const searchByQuery = ComponentToWrap => props => {
  const { location } = props;
  const dispatch = useDispatch();
  const email = useSelector(state => get(state, 'series.email') || get(state, 'session.user.email')) || '';
  const seriesProductId = isBrowser && new URLSearchParams(location.search).get('productId');
  const loadedProductId = useSelector(state => state.series && state.series.productId);
  useEffect(
    () => {
      if (seriesProductId && seriesProductId !== loadedProductId) {
        if (email) {
          dispatch(
            fetchSeriesInfo(email, seriesProductId, false, productId => {
              if (!productId) {
                dispatch(locNavigate('/sarjapaketti'));
              }
            })
          );
        } else {
          dispatch(locNavigate('/sarjapaketti'));
        }
      }
    },
    [dispatch, seriesProductId, email],
    loadedProductId
  );

  return <ComponentToWrap productId={seriesProductId} {...props} />;
};

const SeriesParcelPage = props => {
  analytics.usePageCategory('paketit');
  if (props.productId) {
    return <SeriesInfoWrapper {...props} />;
  } else {
    return <AskSeriesId {...props} />;
  }
};

export default searchByQuery(SeriesParcelPage);
