/** @jsx jsx */
import { jsx, Box, Flex, Styled } from 'theme-ui';
import React, { useRef, useLayoutEffect } from 'react';
import { getTranslate } from 'react-localize-redux';
import { useSelector } from 'react-redux';
import Button from '../components/Button';
import { capitalize } from '../utils/string';
import * as streetAndApartment from '../utils/streetAndApartment';

const Header = ({ children }) => {
  return <Box sx={{ fontWeight: 'medium' }}>{children}</Box>;
};

const Recipient = ({ recipient }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <div sx={{ mb: 3 }}>
      <Header>{translate('series.info.recipient')}</Header>
      <div>{recipient.name}</div>
      <div>{recipient.phone}</div>
      <div>{recipient.email}</div>
      <div>{streetAndApartment.combine(recipient)}</div>
      <div>
        {recipient.postcode} {recipient.city}
      </div>
      <div>{recipient.remarks}</div>
    </div>
  );
};

const PickupPoint = ({ pickupPoint: s }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <div sx={{ mb: 3 }}>
      <Header>{translate('series.info.pickupPoint')}</Header>
      {s.officeName ? (
        <>
          <div>{capitalize(s.officeName)}</div>
          <div>
            {capitalize(s.officeStreetAddress)}, {s.officePostalCode} {capitalize(s.officeCity)}
          </div>
        </>
      ) : (
        <div>{s.officeCode}</div>
      )}
    </div>
  );
};

const ShipmentCode = ({ code }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <div sx={{ mb: 3 }}>
      <Header>{translate('series.info.shipmentCode')}</Header>
      <div>{code}</div>
    </div>
  );
};

const ActivationCode = ({ code }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  return (
    <div sx={{ mb: 3 }}>
      <Header>{translate('series.info.activationCode')}</Header>
      <div>{code}</div>
    </div>
  );
};

export default ({ shipment, onUpdate, scrollVisible }) => {
  const translate = getTranslate(useSelector(state => state.localize));
  const domRef = useRef(null);
  useLayoutEffect(() => {
    if (scrollVisible && domRef) {
      setImmediate(() => {
        window.scrollTo({
          top: domRef.current.offsetTop - 80,
          left: 0,
          behavior: 'smooth',
        });
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // only once
  const { recipient, pickupPoint, shipmentNumber, activationCode } = shipment;

  return (
    <Box sx={{ mt: [4] }} ref={domRef}>
      {onUpdate ? (
        <Button sx={{ float: 'right', ml: [2] }} onClick={onUpdate}>
          {translate('series.info.updateButton')}
        </Button>
      ) : null}
      {recipient ? <Recipient recipient={recipient} /> : null}
      {pickupPoint && pickupPoint.officeCode ? <PickupPoint pickupPoint={pickupPoint} /> : null}
      {shipmentNumber ? <ShipmentCode code={shipmentNumber} /> : null}
      {activationCode ? <ActivationCode code={activationCode} /> : null}
    </Box>
  );
};
