/** @jsx jsx */
import { jsx, Box, Styled } from 'theme-ui';
import React, { useCallback, useState } from 'react';
import { useSelector } from 'react-redux';
import { getTranslate } from 'react-localize-redux';
import { Form, withFormik } from 'formik';
import * as yup from 'yup';
import get from 'lodash/get';
import Button from '../components/Button';
import FormField from './FormField';
import * as api from '../utils/api';
import getLanguage from '../utils/getLanguage';

const EmailForm = props => {
  const translate = getTranslate(useSelector(state => state.localize));

  return (
    <Form
      sx={{
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        mb: 4,
      }}
    >
      <FormField name="email" label={translate('series.form.email')} />
      <Box>
        <Button type="submit" onClick={props.handleSubmit} disabled={props.isSubmitting}>
          {translate('series.forgot.button')}
        </Button>
      </Box>
    </Form>
  );
};

const emailSchema = translate =>
  yup.object().shape({
    email: yup
      .string()
      .required(translate('form.requiredField'))
      .email(translate('form.invalidEmail')),
  });

const EmailFormik = withFormik({
  mapPropsToValues: ({ email }) => ({
    email: email || '',
  }),

  validationSchema: ({ translate }) => emailSchema(translate),

  handleSubmit: async (values, { props: { onSubmit }, ...actions }) => {
    onSubmit(values, actions);
  },

  displayName: 'ForgotSeriesForm',
})(EmailForm);

const STATE = {
  HIDDEN: 0,
  VISIBLE: 1,
  SUBMITTED: 2,
};

export default () => {
  const translate = getTranslate(useSelector(state => state.localize));
  const [state, setState] = useState(STATE.HIDDEN);
  const email = useSelector(state => get(state, 'series.email') || get(state, 'session.user.email')) || '';
  const showForm = useCallback(() => {
    if (state === STATE.HIDDEN) {
      setState(STATE.VISIBLE);
    }
  }, [state]);
  const language = useSelector(state => getLanguage(state));

  const onSubmit = useCallback(async ({ email }, { setSubmitting }) => {
    try {
      await api.forgotSeriesNumber(email, language);
      setState(STATE.SUBMITTED);
    } catch (err) {
      console.warn('Failed to request forgotten series shipment numbers.');
    }
    setSubmitting(false);
  }, []);
  const formProps = { translate, email, onSubmit };

  return (
    <Box sx={{ mt: 3 }}>
      <Button variant="plain" onClick={showForm} sx={{ fontWeight: 'medium' }}>
        {translate('series.forgot.title')}
      </Button>
      {state === STATE.HIDDEN ? null : (
        <>
          <Styled.p>{translate('series.forgot.description')}</Styled.p>
          {state === STATE.VISIBLE ? (
            <EmailFormik {...formProps} />
          ) : (
            <GreenBox>{translate('series.forgot.emailSent')}</GreenBox>
          )}
        </>
      )}
    </Box>
  );
};

const GreenBox = ({ children }) => (
  <Box
    sx={{
      bg: 'green',
      color: 'black',
      p: [3],
      mt: [3],
      borderRadius: '8px',
    }}
  >
    {children}
  </Box>
);
